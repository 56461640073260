body {
  //background-color: $mole-grey-30;
  background-color: white;
  @include breakpoint(large up) {
    margin-right: 17rem;
  }
}

div.main {
  border: solid 1px #dddddd;
  padding: 1.75rem;
  border-radius: $border-radius;
  background-color: white;
  margin-bottom: 2rem;

  @include breakpoint(small only) {
      padding: 0.75rem;
    }
}

div.nav {

  /*padding-right: 0.5rem;
  @include breakpoint(small only) {
    padding-right: 0;
  }*/

  div {
    padding: 0.75rem 1.25rem;
    background-color: white; //lighten($mole-grey, 60%);
    border: solid 1px #dddddd;
    border-radius: $border-radius;
    margin-bottom: 1rem;
  }
}

div#c_top {
  padding-bottom: 0.75rem;
  margin-bottom: 1.75rem;
  border-bottom: solid 1px $mole-grey-30;

}


div#c_bottom {
  //border-top: solid 1px $mole-grey-30;
  //margin-top: 0.75rem;
  padding-top: 0.75rem;
}

table {
  width: auto !important;
}

#navoffcanvas {
  @include breakpoint(medium down) {
    padding-top: 1rem;
  }
}

.w100 {
  width: 100%;
}
