div.promo {
  margin: 0 0 1rem 0;

  color: $logo-color;

  p {
    font-size: 1.25rem;
    margin-top: 2rem;

    @include breakpoint(medium down) {
      font-size: 1.125rem;
      margin-bottom: 0.25rem;
      margin-top: 0;
    }
  }

  div.text {
    border: solid 3px $logo-color;
    border-radius: 5px;
    //background-color: $mole-grey-light;
    padding: 1rem;
    text-align: center;

    @include breakpoint(small only) {
      margin-bottom: 1rem;
    }

    h2 {
      font-size: 1.75rem;
      font-weight: bold;

      @include breakpoint(small only) {
        margin-bottom: 1rem;
        font-size: 1.25rem;
      }

    }

    a {
      color: $logo-color;
      font-weight: bold;
    }
  }

  div.right {
    text-align: right;
  }

  &.promo-right {

    background-color: $mole-grey;
    color: white;

    @include breakpoint(large up) {
      position: fixed;
      right: 0;
      top: 0;
      width: 16rem;
      height: 100%;
      padding-top: 6rem;
    }

    div.text {

      a {
        color: white;
      }
    }

  }
}
