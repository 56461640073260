div.a, div.b, div.c {
  font-family: 'Roboto Slab', Times, serif;
  margin-bottom: 1rem;

  &.b {
    margin-left: 1rem;
  }

  &.c {
    margin-left: 2rem;
  }
}


div.nextArticle {
  text-align: right;
}


.nav_left {

  ul {
    margin-left: 0;
    margin-bottom: 0;
    li {
      ul {
        margin-left: 2rem;
        li {
          list-style: square;
        }
      }
    }
  }

  li {
    list-style: none;

    &#keywordsHeader, &#localNavigationHeader {
      font-variant: small-caps;
      font-weight: bold;
      margin: 1.0rem 0 0.25rem 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

div.stromecek {

  div.stromecek {
    margin-left: 2rem;
  }
}

div.oddelovac {
  font-weight: bold;
  border-bottom: solid 1px #cccccc;
}

tr.articleHeader {
  font-family: 'Roboto Slab', Times, serif;
  font-weight: bold;

  td {
    background-color: #f0f0f0;
  }
}
