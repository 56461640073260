div.header {
  color: $logo-color;
  padding: 1.5rem 2.5rem 0.75rem 2.5rem;
  margin-bottom: 0;

  img {
    margin-bottom: 6px;  // to fix the positioning of the image
  }

  @include breakpoint(medium down) {
    padding: 1rem 0;

    img {
      width: 120px;
      margin-bottom: 2px;  // to fix the positioning of the image
    }
  }

  h1 {
    font-weight: 800;
    font-family: Montserrat, Arial, sans-serif;
    letter-spacing: -1px;

    @include breakpoint(medium down) {
      font-size: 1.625rem;
    }

    a {
      color: $eco-green;

      span {
        color: $mole-grey;
      }
    }

  }

  .menu_icon {
    @include hamburger();
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

}
